import { SyntheticEvent, KeyboardEvent, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  useTheme
} from "@mui/material";
import { TextInput } from "../TextInput";
import { Chip } from "../Chip";
import { KeyTitlePair } from "../../../types";
import { MultiValueInputWrapperProps } from "./MultiValueInput.props";
import { Wrapper, makePaperStyles } from "./MultiValueInput.styles";

export const MultiValueInput = ({
  label,
  value,
  infoText,
  error,
  errorMessage,
  onChange,
  chipIcon,
  marginBottom,
  marginTop,
  placeholder,
  minLength,
  maxLength,
  ...props
}: MultiValueInputWrapperProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [inputValue, setInputValue] = useState("");

  const inputValueAlreadyExists = value && value?.some(({ key }) => key === inputValue.trim());

  const emptyValue = inputValue && !inputValue.trim();

  const minLenghtError = inputValue && minLength && inputValue.length < minLength;

  const maxLengthError = inputValue && maxLength && inputValue.length > maxLength;

  const internalError = inputValueAlreadyExists || emptyValue || minLenghtError || maxLengthError;

  const options: KeyTitlePair[] =
    inputValue && !internalError ? [{ key: inputValue, title: inputValue }] : [];

  const handleInputChange = useCallback((_: SyntheticEvent, value: string) => {
    setInputValue(value);
  }, []);

  const getOptionLabel = useCallback((option: string | KeyTitlePair) => {
    let optionValue;
    if (typeof option === "string") {
      optionValue = option;
    } else {
      optionValue = option.title;
    }

    return `Add: ${optionValue}`;
  }, []);

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement> & { defaultMuiPrevented?: boolean }) => {
      if (event.key === "Enter" && inputValue) {
        if (internalError) {
          event.defaultMuiPrevented = true;
        }
      }
    },
    [inputValue, internalError]
  );

  const handleChange = useCallback(
    (_: SyntheticEvent, value: (string | KeyTitlePair)[]) => {
      const mappedValues = value.map((item) => {
        if (typeof item === "string") {
          return { key: item.trim(), title: item.trim() };
        }

        return { key: item.key.trim(), title: item.title.trim() };
      });
      onChange(mappedValues);
    },
    [onChange]
  );

  const errorState = internalError || error;

  const emptyInputValueError = emptyValue ? t("global.error.multiLineInputEmpty") : errorMessage;

  const minLengthErrorMessage = minLenghtError
    ? t("global.error.multiLineInputTooShort", { minLength })
    : emptyInputValueError;

  const maxLengthErrorMessage = maxLengthError
    ? t("global.error.multiLineInputTooLong", { maxLength })
    : minLengthErrorMessage;

  const errorMessageToShow = inputValueAlreadyExists
    ? t("global.error.multiLineInputAlreadyAdded")
    : maxLengthErrorMessage;

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <TextInput
          {...props}
          {...params}
          placeholder={value?.length ? "" : placeholder}
          label={label}
          error={errorState}
          errorMessage={errorMessageToShow}
          marginTop={marginTop}
          marginBottom={marginBottom}
          infoText={infoText}
        />
      );
    },
    [
      label,
      errorState,
      errorMessageToShow,
      marginTop,
      marginBottom,
      infoText,
      props,
      value,
      placeholder
    ]
  );

  const renderTags = useCallback(
    (value: readonly KeyTitlePair[], getTagProps: AutocompleteRenderGetTagProps) =>
      value.map(({ title }, index) => {
        const { key, ...tagProps } = getTagProps({ index });
        return <Chip {...tagProps} key={key} label={title} icon={chipIcon} />;
      }),
    [chipIcon]
  );

  return (
    <Wrapper
      value={value}
      options={options}
      onChange={handleChange}
      fullWidth
      onInputChange={handleInputChange}
      onKeyDown={handleInputKeyDown}
      multiple
      freeSolo
      disableClearable
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      slotProps={{
        paper: {
          sx: makePaperStyles(theme)
        }
      }}
      renderTags={renderTags}
    />
  );
};
