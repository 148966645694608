import { SummernoteCustomEventType } from "./Wysiwyg.props";

const prepareHeadElement = () => `
  <head>
    <link rel="stylesheet" href="https://netdna.bootstrapcdn.com/bootstrap/3.3.5/css/bootstrap.css">
    <link href="https://fonts.googleapis.com/css?family=Exo+2:600|Maven+Pro:400,500,700" rel="stylesheet">
    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.js"></script>
    <script type="text/javascript" src="https://netdna.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.js"></script>

    <!-- codemirror -->
    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.41.0/codemirror.min.css" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.41.0/theme/monokai.min.css">
    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.41.0/codemirror.js"></script>
    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.41.0/mode/xml/xml.min.js"></script>


    <!-- include summernote css/js -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/summernote@0.8.18/dist/summernote-lite.min.css">
    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.12/summernote.min.js"></script>
    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.12/lang/summernote-de-DE.min.js"></script>
    <style type="text/css">body{background-color: transparent}.panel{margin-bottom: 0px !important;}</style>
  </head>
`;

const prepareBodyContent = (key: string) => `
  <div id="summernote${key}"></div>
`;

const prepareCustomButton = (key: string) => `
  var toggleCodeViewButton = function () {
    let ui = $.summernote.ui;
    let button = ui.button({
      contents: '<i class="note-icon-code"/>',
      tooltip: customTranslations[language].toggleCode,
      className: 'own-code-btn',
      container: 'body',
      click: function(contents){
        $("#summernote${key}").summernote('codeview.toggle');
        $('.own-code-btn').toggleClass('disabled', false);
        $('.own-code-btn').attr('disabled', false);
        window.parent.postMessage({
          type: '${SummernoteCustomEventType.CODE_VIEW_TOGGLE}',
          state:  $("#summernote${key}").summernote('codeview.isActivated'),
          index: '${key}'
        }, '*');
      }
    });

    return button.render();
  };
`;

const initializeSummernote = (key: string) => `
  $("#summernote${key}").summernote({
    height: 320,
    disableResizeEditor: true,
    fontNames: ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Helvetica', 'Impact', 'Lucida Grande', 'Maven Pro', 'Tahoma', 'Times New Roman', 'Verdana'],
    placeholder: 'Paste in your email signature',
    lang: language === 'de' ? 'de-DE' : 'en-Us',
    callbacks: {
      onChange: function(contents) {
        window.parent.postMessage({
          type: '${SummernoteCustomEventType.CHANGE}',
          value: contents,
          index: '${key}'
        }, '*');
      },
    },
    codemirror: {
      mode: 'text/html',
      htmlMode: true,
      lineNumbers: true,
      theme: 'monokai'
    },
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'italic', 'underline', 'clear']],
      ['fontname', ['fontname']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'hr']],
      ['mybutton', ['togglecode']]
    ],
    buttons: {
        togglecode: toggleCodeViewButton
    }
  });

  $("#summernote${key}").summernote('code', initialValue);
`;

const prepareScript = (key: string, language: string, initialValue: string) => `
  <script>
    let customTranslations = {
      "de": {
        toggleCode: "Quellcode anzeigen"
      },
      "en": {
        toggleCode: "Code View"
      }
    }

    let language = \"${language.toString()}\";

    let initialValue = \"${initialValue.toString()}\";

    ${prepareCustomButton(key)}

    ${initializeSummernote(key)}
  </script>
`;

export const prepareHtml = (key: string, language: string, initialValue: string) => `
  <html>
    ${prepareHeadElement()}
    <body>
      ${prepareBodyContent(key)}
      ${prepareScript(key, language, initialValue)}
    </body>
  </html>
`;
