import { FieldValidator } from "final-form";
import isEmail from "validator/es/lib/isEmail";
import { parsePhoneNumber } from "libphonenumber-js/max";

export const emailValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "settingsModule.errors.required";
  }

  if (!isEmail(value)) {
    return "settingsModule.errors.invalidEmail";
  }

  if (value.length > 128) {
    return "settingsModule.errors.lessThan128Characters";
  }
};

export const phoneValidator: FieldValidator<string> = (value) => {
  try {
    if (value && !parsePhoneNumber(value).isValid()) {
      return "settingsModule.errors.invalidPhone";
    }
  } catch (error) {
    return "settingsModule.errors.invalidPhone";
  }
};

export const nameValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "settingsModule.errors.required";
  }

  if (value.length < 2) {
    return "settingsModule.errors.biggerThanTwoCharacters";
  }

  if (value.length > 128) {
    return "settingsModule.errors.lessThan128Characters";
  }
};
