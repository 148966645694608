import { FieldValidator } from "final-form";

export const abandonmentMailsWaitingTimeValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return "questionnaireModule.errors.shouldBeANumber";
  }

  if (numberValue < 1) {
    return "questionnaireModule.errors.lessThenOne";
  }
};

export const browseAbandonmentTriggerWaitingTimeValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return "questionnaireModule.errors.shouldBeANumber";
  }

  if (numberValue < 24) {
    return "questionnaireModule.errors.biggerThan24";
  }

  if (numberValue > 4320) {
    return "questionnaireModule.errors.lessThan4320";
  }
};

export const comeBackMailingWaitingTimeValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return "questionnaireModule.errors.shouldBeANumber";
  }

  if (numberValue < 24) {
    return "questionnaireModule.errors.biggerThan24";
  }

  if (numberValue > 12960) {
    return "questionnaireModule.errors.lessThan12960";
  }
};
