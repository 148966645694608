import { useCallback, SyntheticEvent, FocusEventHandler, FocusEvent, MouseEvent } from "react";
import { ContactPerson } from "api";
import { FormProps, Form, Field, FormSpy } from "react-final-form";
import { FormApi } from "final-form";
import {
  ContainedButton,
  TextInput,
  Accordion,
  AccordionSummary,
  ButtonsWrapper,
  TitleInput,
  IconButton,
  AccordionDetails,
  FieldLine
} from "shared";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { emailValidator, phoneValidator, nameValidator } from "./ContactPersonFormBase.validation";
import { ContactPersonFormBaseProps } from "./ContactPersonFormBase.props";

export const ContactPersonFormBase = ({
  initialState,
  formOpened,
  expandIcon,
  onOpen,
  onClose,
  onSubmit,
  onDelete,
  onValuesChange
}: ContactPersonFormBaseProps) => {
  const { t } = useTranslation();

  const handleFormSubmit: FormProps<ContactPerson, ContactPerson>["onSubmit"] = useCallback(
    (values, form) => {
      return onSubmit(values, form);
    },
    [onSubmit]
  );

  const handleDelete = useCallback(
    (form: FormApi<ContactPerson>) => (event: SyntheticEvent) => {
      event.stopPropagation();
      if (onDelete) onDelete(initialState.contactPersonId, form);
    },
    [initialState, onDelete]
  );

  const handleFormOpen = useCallback(() => {
    if (!formOpened && onOpen) onOpen();
    if (formOpened && onClose) onClose();
  }, [formOpened, onOpen]);

  const handleTitleFocus = useCallback(
    (onFocus: FocusEventHandler<HTMLInputElement>) => (e: FocusEvent<HTMLInputElement>) => {
      if (!formOpened && onOpen) {
        onOpen();
      }
      onFocus(e);
    },
    [formOpened, onOpen]
  );

  const handleTitleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <Form
      initialValues={initialState}
      onSubmit={handleFormSubmit}
      render={({ dirty, handleSubmit, submitErrors, form, submitting }) => (
        <Accordion expanded={formOpened}>
          <AccordionSummary onClick={handleFormOpen} expandIcon={expandIcon}>
            <Field
              name="title"
              render={({
                input: { value, onChange, onBlur, onFocus },
                meta: { error, touched, modifiedSinceLastSubmit }
              }) => (
                <TitleInput
                  placeholder={t("settingsModule.contactPersons.form.title")}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  onClick={handleTitleClick}
                  onFocus={handleTitleFocus(onFocus)}
                  error={
                    (error || (submitErrors?.["title"] && !modifiedSinceLastSubmit)) && touched
                  }
                />
              )}
            />
            <ButtonsWrapper>
              {onDelete && (
                <IconButton disabled={submitting} onClick={handleDelete(form)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </ButtonsWrapper>
          </AccordionSummary>
          <AccordionDetails>
            <FieldLine>
              <Field
                name="email"
                validate={emailValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    required
                    label={t("settingsModule.contactPersons.form.email")}
                    placeholder={t("global.placeholders.email")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["email"] && !modifiedSinceLastSubmit)) && touched
                    }
                    errorMessage={t(submitErrors?.["email"] ?? error)}
                  />
                )}
              />
              <Field
                name="phone"
                validate={phoneValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    label={t("settingsModule.contactPersons.form.phone.label")}
                    placeholder={t("settingsModule.contactPersons.form.phone.placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["phone"] && !modifiedSinceLastSubmit)) && touched
                    }
                    errorMessage={t(submitErrors?.["phone"] ?? error)}
                  />
                )}
              />
            </FieldLine>
            <FieldLine>
              <Field
                name="firstName"
                validate={nameValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    required
                    label={t("settingsModule.contactPersons.form.firstName.label")}
                    placeholder={t("settingsModule.contactPersons.form.firstName.placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["firstName"] && !modifiedSinceLastSubmit)) &&
                      touched
                    }
                    errorMessage={t(submitErrors?.["firstName"] ?? error)}
                  />
                )}
              />
              <Field
                name="lastName"
                validate={nameValidator}
                render={({
                  input: { value, onChange, onBlur, onFocus },
                  meta: { error, touched, modifiedSinceLastSubmit }
                }) => (
                  <TextInput
                    required
                    label={t("settingsModule.contactPersons.form.lastName.label")}
                    placeholder={t("settingsModule.contactPersons.form.lastName.placeholder")}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    error={
                      (error || (submitErrors?.["lastName"] && !modifiedSinceLastSubmit)) && touched
                    }
                    errorMessage={t(submitErrors?.["lastName"] ?? error)}
                  />
                )}
              />
            </FieldLine>
            <ContainedButton
              type="submit"
              disabled={!dirty}
              loading={submitting}
              onClick={handleSubmit}
            >
              {t("settingsModule.contactPersons.form.saveButton")}
            </ContainedButton>
            {onValuesChange && (
              <FormSpy
                subscription={{ values: true }}
                onChange={({ values }) => onValuesChange(values as ContactPerson, form)}
              />
            )}
          </AccordionDetails>
        </Accordion>
      )}
    />
  );
};
