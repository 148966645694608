import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContactPerson } from "api";
import { AxiosError } from "axios";
import { useCallback } from "react";
import {
  useQueryConfig,
  useSelectedShop,
  NetworkError,
  useErrorFormatter,
  useNotifications
} from "shared";
import { FormProps } from "react-final-form";
import { putContactPerson } from "../../api";

export const useUpdateContactPerson = () => {
  const { queryName, limit, offset } = useQueryConfig();

  const { selectedShopId } = useSelectedShop();

  const queryClient = useQueryClient();

  const formatError = useErrorFormatter();

  const { showNotification } = useNotifications();

  const { mutateAsync: updateContactPerson } = useMutation<
    void,
    AxiosError<{ message: string }>,
    ContactPerson & { shopId: number }
  >(putContactPerson);

  const executeUpdateContactPerson: FormProps<ContactPerson, ContactPerson>["onSubmit"] =
    useCallback(
      async (values, form) => {
        try {
          await updateContactPerson({
            shopId: selectedShopId!,
            title: values.title,
            contactPersonId: values.contactPersonId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone || undefined
          });

          queryClient.invalidateQueries([queryName, selectedShopId, limit, offset]);

          form.reset();

          showNotification("settingsModule.contactPersons.updateSuccessMessage", "success");
        } catch (error) {
          return formatError(error as NetworkError);
        }
      },
      [queryName, selectedShopId, limit, offset, showNotification]
    );

  return executeUpdateContactPerson;
};
