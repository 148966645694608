import { ConditionKind, ConditionsObjects, NumberConditionOperators } from "api";
import { ConditionsObjectsInitialFormValues, ConditionsObjectsFormValues } from "../types";

export const mapFormToCustomConditions = (
  conditions: ConditionsObjectsFormValues[] | undefined
): ConditionsObjects[] | undefined => {
  if (!conditions) return;

  const parsedValues: (ConditionsObjects | undefined)[] = conditions.map((condition) => {
    switch (condition.kind) {
      case ConditionKind.CUSTOMER_GROUP:
        return {
          id: condition.id,
          kind: condition.kind,
          operator: condition.operator,
          value: condition.value.map(({ key }) => key)
        };
      case ConditionKind.REVENUE:
        switch (condition.operator) {
          case NumberConditionOperators.BETWEEN:
            return {
              id: condition.id,
              kind: condition.kind,
              operator: condition.operator,
              value: {
                less: condition.betweenValue.less,
                more: condition.betweenValue.more
              }
            };
          case NumberConditionOperators.LESS_OR_EQUAL:
          case NumberConditionOperators.MORE_OR_EQUAL:
          case NumberConditionOperators.EQUAL:
            return {
              id: condition.id,
              kind: condition.kind,
              operator: condition.operator,
              value: condition.numericValue
            };
        }
    }
  });

  return parsedValues.filter((condition) => condition !== undefined) as ConditionsObjects[];
};

export const mapCustomConditionsToForm = (
  conditions: ConditionsObjects[] | undefined
): ConditionsObjectsInitialFormValues[] | undefined => {
  if (!conditions) return;

  const parsedValues: (ConditionsObjectsInitialFormValues | undefined)[] = conditions.map(
    (condition) => {
      switch (condition.kind) {
        case ConditionKind.CUSTOMER_GROUP:
          return {
            id: condition.id,
            kind: condition.kind,
            operator: condition.operator,
            value: condition.value.map((conditionValue) => ({
              key: conditionValue,
              title: conditionValue
            }))
          };
        case ConditionKind.REVENUE:
          switch (condition.operator) {
            case NumberConditionOperators.BETWEEN:
              return {
                id: condition.id,
                kind: condition.kind,
                operator: condition.operator,
                betweenValue: {
                  less: condition.value.less,
                  more: condition.value.more
                },
                numericValue: undefined
              };
            case NumberConditionOperators.LESS_OR_EQUAL:
            case NumberConditionOperators.MORE_OR_EQUAL:
            case NumberConditionOperators.EQUAL:
              return {
                id: condition.id,
                kind: condition.kind,
                operator: condition.operator,
                numericValue: condition.value,
                betweenValue: {
                  less: undefined,
                  more: undefined
                }
              };
          }
      }
    }
  );

  return parsedValues.filter(
    (condition) => condition !== undefined
  ) as ConditionsObjectsFormValues[];
};
