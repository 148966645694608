import { FieldValidator } from "final-form";
import isEmail from "validator/es/lib/isEmail";
import { isEmptyString } from "shared/utils/validation";
import { parsePhoneNumber } from "libphonenumber-js/max";

export const emailValidator: FieldValidator<string> = (value) => {
  if (!value) {
    return "questionnaireModule.errors.required";
  }

  if (!isEmail(value)) {
    return "questionnaireModule.errors.invalidEmail";
  }

  if (value.length > 128) {
    return "questionnaireModule.errors.lessThan128Characters";
  }
};

export const phoneValidator = (value: string, phoneOptional: boolean): string | undefined => {
  if (!phoneOptional && !value) {
    return "questionnaireModule.errors.required";
  }

  try {
    if (value && !parsePhoneNumber(value).isValid()) {
      return "questionnaireModule.errors.invalidPhone";
    }
  } catch (error) {
    return "questionnaireModule.errors.invalidPhone";
  }
};

export const nameValidator: FieldValidator<string> = (value) => {
  if (isEmptyString(value)) {
    return "questionnaireModule.errors.required";
  }

  if (value.length < 2) {
    return "questionnaireModule.errors.biggerThanTwoCharacters";
  }
};
